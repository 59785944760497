@import "../ThemeVariables.less";


@import "~antd/dist/antd.less";
@path-fonts: "./assets/fonts/Roboto";


@font-face {
  font-family: "Roboto";
  src: url("@{path-fonts}/Roboto-Light.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("@{path-fonts}/Roboto-Regular.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("@{path-fonts}/Roboto-Medium.ttf");
  font-weight: 600;
}

// @font-face {
//   font-family: "Roboto";
//   src: url("@{path-fonts}/Roboto-SemiBold.ttf");
//   font-weight: 700;
// }

@font-face {
  font-family: "Roboto";
  src: url("@{path-fonts}/Roboto-Bold.ttf");
  font-weight: 800;
}

body {
  font-family: "Roboto";
}

html {
  scroll-behavior: smooth;
}

// input {
//   border: 1px solid #adadad;
//   padding: 6px 10px;
//   border-radius: 6px;
//   font-size: 1rem;
//   margin-bottom: 15px;
// }

.searchbar {
  border-radius: 30px;
  input {
    border-radius: 100px !important;
    position: relative;
    padding-left: 40px;
  }
  .ant-input-group-addon {
    position: absolute;
    left: 5px !important;
    top: 0;
    bottom: 0;
    z-index: 9;
    margin: auto;
    border: 0;
    background: transparent;
    button {
      border: none;
      background: transparent;
    }
  }
}

.text-center {
  text-align: center;
}
.text-black {
  color: black !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-25 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.text-primary {
  color: @primary !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.word-break {
  word-break: break-all;
}

.w-100 {
  width: 100% !important;
}
.w-40 {
  width: 40% !important;
}
.br-40 {
  border-radius: 40px !important;
}
.blue-border-btn {
  border: 1px solid @primary;
  border-radius: 6px;
  color: @primary;
  background-color: transparent;
  font-weight: 600;
  // &:hover,
  // &:focus {
  //   background-color: @primary;
  //   color: white;
  //   border-color: @primary;
  // }
}
.blue-btn {
  background-color: @primary;
  border-color: @primary;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  &:hover {
    background-color: transparent;
    color: @primary;
    border-color: @primary;
  }
}

.blue-btn:focus {
  background-color: @primary;
  border-color: @primary;
  border-radius: 6px;
  color: white;
  font-weight: 600;
}
.table-header {
  button {
    margin-left: 10px;
  }
  .searchbar {
    .ant-input-group-addon {
      left: -8px !important;
    }
    .ant-input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0 !important;
    }
  }
  .ant-select-selection-placeholder {
    color: #646464;
  }
  .ant-dropdown-trigger {
    .anticon-down {
      transform: translateY(3px);
      font-size: 14px;
    }
  }
}

.bordered-btn {
  background-color: transparent;
  border: 1px solid @primary;
  color: @primary;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  height: auto;
}

.common-table {
  margin-top: 20px;
  table {
    border: 0.5px solid #e0e0e0;
    border-top: 0;
  }
  thead {
    th {
      background-color: #f2f9fd;
      font-weight: bolder;
    }
  }
  .ant-checkbox-inner {
    background: transparent;
    border-color: #525252;
    border-radius: 4px;
  }
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background: @primary;
    }
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: @primary;
  }
  // .ant-table-cell.ant-table-selection-column {
  //   padding-left: 18px;
  // }
  .actions {
    text-align: center;
    border-left: 0.5px solid #e0e0e0;
  }
  th.actions {
    border-left: 0;
  }
  a {
    color: black;
  }
  .ant-pagination-item-link {
    border: none;
    color: #161616;
    font-weight: bolder;
  }
  .ant-pagination-item {
    border: none;
  }
  .ant-pagination-item-active {
    background-color: @primary;
    a {
      color: white;
    }
  }
  td {
    color: #161616;
    font-weight: 500;
  }
}

.no-header {
  table {
    border: 0.8px solid #adadad;
    border-top: 0.8px solid #adadad;
  }
  .border-left {
    border-left: 0.8px solid #adadad;
  }
}

.form-modal {
  // width: 700px !important;
  .ant-modal-header {
    border-bottom: 0 !important;
    padding: 30px !important;
  }
  .ant-modal-body {
    padding-top: 0px !important;
    padding: 30px;
  }
  .ant-modal-title {
    text-align: center;
    color: #161616;
    font-weight: bolder;
    text-transform: capitalize;
  }
  .ant-select-selector,
  .ant-input,
  .ant-picker {
    border-radius: 5px !important;
  }
  label {
    color: #646464;
    font-weight: 600;
  }
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  .ant-modal-close-x {
    span.anticon.anticon-close.ant-modal-close-icon {
      background: @primary !important;
      padding: 5px !important;
      border-radius: 50% !important;
      color: #fff !important;
    }
  }
}

.ant-select-selector:hover {
  border-color: @primary !important;
}
.ant-select-selector:focus {
  border-color: @primary !important;
  box-shadow: none !important;
}

.ant-input:focus {
  border-color: @primary !important;
  box-shadow: none;
}
.ant-input:hover {
  border-color: @primary !important;
}

.ant-select-selection-search-input {
  color: @primary !important;
}
.back-btn {
  font-weight: bolder;
  font-size: 16px;
}

.border-radius-6px {
  border-radius: 6px;
}

.ant-select-selector,
.ant-input,
.ant-picker,
.ant-input-password {
  border-radius: 5px !important;
}

.update-form {
  label {
    font-weight: 600;
    color: #646464;
  }
  .searchbar {
    .ant-input {
      border-radius: 6px !important;
    }
  }
  .ant-upload {
    width: 100%;
    .ant-btn {
      width: 100%;
      height: 142px;
      font-size: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 35px;
        color: #646464;
      }
    }
  }
}
.h-auto {
  height: auto !important;
}
.h-100 {
  height: 100% !important;
}
.h-60 {
  height: 60%;
}
.h-30 {
  height: 30px;
}
.h-20 {
  height: 20px;
}
.h-75 {
  height: 75px;
}
.h-15em {
  height: 1.5em;
}
.hide-table-head {
  margin-top: 0 !important;
  .ant-table-thead {
    display: none;
  }
  table {
    border: 0 !important;
    tr.ant-table-row:hover > td {
      background: #f4f4f4 !important;
    }
  }
  .ant-table {
    margin: -16px -16px -16px 62px !important;
    .ant-table-cell {
      background: #f4f4f4;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.hide-th.hide-table-head {
  .ant-table {
    margin: -16px -16px -16px 62px !important;
  }
}

table {
  .ant-table-thead th {
    padding: 10px 20px !important;
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 6px 20px !important;
    }
  }
  th.ant-table-cell.ant-table-selection-column {
    padding: 16px 16px !important;
  }
}

.border-bottom-only {
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: inherit !important;
  box-shadow: none !important;
}

.divider {
  height: 100%;
}

@media screen and (min-width: 300px) and (max-width: 780px) {
  .adminLayout {
    .sidebar {
      footer {
        display: none !important;
      }
    }
  }
  .mtr-20 {
    margin-top: 20px;
  }
  .responsive-hide {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
}


.app {
  width: 100%;
  // min-height: 50vh !important;
  // background: #dddbd1;
  position: relative;
}

.app::before {
  width: 100%;
  height: 120px;
  top: 0;
  left: 0;
  // background: rgb(0, 150, 136);
  position: absolute;
  content: "";
  z-index: 1;
}

.app__mobile-message {
  display: none;
}

.app-content {
  // width: 100%;
  height: 85vh;
  max-width: 1450px;
  margin: -24px;
  // margin: 0 auto;
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
  position: relative;
  z-index: 100;
  display: flex;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .app__mobile-message {
      padding-top: 200px;
      text-align: center;
      font-size: 1.2rem;
      display: block;
  }
  .app-content {
      display: none;
  }
}

@media screen and (min-width: 1450px) {
  .app {
      padding: 20px;
  }
  .app-content {
      height: calc(100vh - 40px);
  }
}

